import { AxiosResponse } from 'axios';

import {
	GetColumnFiltersPayload,
	GetReportsExportPayload,
	GetReportsPayload,
	GetReportsStatsPayload,
} from '@/api/reports/request';
import {
	GetApplicationColumnFilterResponse,
	GetReportsColumnsResponse,
	GetReportsResponse,
	ReportStatsResponse,
} from '@/api/reports/response';
import { ClientService } from '@/types/api';

export class ReportsApi {
	private prefix = 'v2/reports';

	private reportsPrefix = '/v2/applications';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getReports({ filters, ...params }: GetReportsPayload): Promise<AxiosResponse<GetReportsResponse>> {
		return this.clientService.post(`${this.prefix}`, filters, {
			params,
		});
	}

	public async getReportsStats(params: GetReportsStatsPayload): Promise<AxiosResponse<ReportStatsResponse>> {
		return this.clientService.get(`${this.reportsPrefix}/project-reports`, {
			params,
		});
	}

	public async getColumns(): Promise<AxiosResponse<GetReportsColumnsResponse>> {
		return this.clientService.get(`${this.prefix}/columns`);
	}

	public async getColumnFilters({
		key,
		...payload
	}: GetColumnFiltersPayload): Promise<AxiosResponse<GetApplicationColumnFilterResponse>> {
		return this.clientService.get(`${this.prefix}/columns/filters/${key}`, {
			params: payload,
		});
	}

	public async getExport({ filters, ...params }: GetReportsExportPayload): Promise<AxiosResponse<Blob>> {
		return this.clientService.post(`${this.prefix}/excel`, filters, {
			params,
			responseType: 'blob',
		});
	}

	public async getProjectStatusReport(params: GetReportsStatsPayload): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`${this.reportsPrefix}/export-projects-count-grouped-by-status/excel`, {
			params,
			responseType: 'blob',
		});
	}

	public async getProjectDirectionReport(params: GetReportsStatsPayload): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`${this.reportsPrefix}/export-projects-count-grouped-by-direction/excel`, {
			params,
			responseType: 'blob',
		});
	}

	public async getProjectThemeReport(params: GetReportsStatsPayload): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`${this.reportsPrefix}/export-projects-count-grouped-by-theme/excel`, {
			params,
			responseType: 'blob',
		});
	}

	public async getReportByRegionExcel(params: GetReportsStatsPayload): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`${this.reportsPrefix}/reports/by-region/excel`, {
			params,
			responseType: 'blob',
		});
	}

	public async getReportByRegionPdf(params: GetReportsStatsPayload): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`${this.reportsPrefix}/reports/by-region/pdf`, {
			params,
			responseType: 'blob',
		});
	}

	public async getReportByCountryExcel(params: GetReportsStatsPayload): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`${this.reportsPrefix}/reports/by-country/excel`, {
			params,
			responseType: 'blob',
		});
	}

	public async getReportByCountryPdf(params: GetReportsStatsPayload): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`${this.reportsPrefix}/reports/by-country/pdf`, {
			params,
			responseType: 'blob',
		});
	}
}
