import { AxiosResponse } from 'axios';

import {
	CollegialMeetingVotingPayload,
	CollegialMeetingVotingThirdPartyPayload,
	CreateCollegialPayload as CreateMeetingPayload,
	GetCollegialsPayload,
	MoveSignToActivePayload,
	UpdateMeetingPayload,
} from '@/api/collegials/request';
import {
	GetAllCollegialMeetingsResponse,
	GetCollegialLogsResponse,
	GetCollegialQuestionResponse,
	GetCountVotedParticipantsResponse,
	GetMeetingResponse,
	GetMeetingVotingResultsResponse,
	GetNotEditabjeSubjectsResponse,
	GetVotingDetailsResponse,
} from '@/api/collegials/response';
import { ClientService } from '@/types/api';

export class CollegialsApi {
	private prefix = 'v2/collegial';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getVotingDetails(meetingId: number): Promise<AxiosResponse<GetVotingDetailsResponse>> {
		return this.clientService.get(`${this.prefix}/${meetingId}/voting`);
	}

	public async votingByUser(meetingId: number, payload: CollegialMeetingVotingPayload): Promise<AxiosResponse<void>> {
		return this.clientService.post(`${this.prefix}/${meetingId}/voting`, payload);
	}

	public async votingForParticipant(
		meetingId: number,
		payload: CollegialMeetingVotingThirdPartyPayload
	): Promise<AxiosResponse<void>> {
		return this.clientService.post(`${this.prefix}/${meetingId}/voting/third-party`, payload);
	}

	public async getMeetingVotingResults(meetingId: number): Promise<AxiosResponse<GetMeetingVotingResultsResponse>> {
		return this.clientService.get(`${this.prefix}/${meetingId}/voting/results`);
	}

	public async getCountVotedParticipants(
		meetingId: number
	): Promise<AxiosResponse<GetCountVotedParticipantsResponse>> {
		return this.clientService.get(`${this.prefix}/${meetingId}/count-voted-participants`);
	}

	public async getNotEditableSubjects(meetingId: number): Promise<AxiosResponse<GetNotEditabjeSubjectsResponse>> {
		return this.clientService.get(`${this.prefix}/${meetingId}/can-edit`);
	}

	public async getQuestions(typeId: number): Promise<AxiosResponse<GetCollegialQuestionResponse>> {
		return this.clientService.get(`${this.prefix}/questions?typeId=${typeId}`);
	}

	public async getLogs(meetingId: number): Promise<AxiosResponse<GetCollegialLogsResponse>> {
		return this.clientService.get(`${this.prefix}/${meetingId}/logs`);
	}

	public subscribeToMeetingProgress(meetingId: number): EventSource {
		return new EventSource(`/backApi/${this.prefix}/${meetingId}/voting/progress`);
	}

	public async createMeeting(payload: CreateMeetingPayload): Promise<AxiosResponse<number>> {
		return this.clientService.post(`${this.prefix}`, payload);
	}

	public async getMeeting(meetingId: number): Promise<AxiosResponse<GetMeetingResponse>> {
		return this.clientService.get(`${this.prefix}/${meetingId}`);
	}

	public async updateMeeting(meetingId: number, payload: UpdateMeetingPayload): Promise<AxiosResponse<number>> {
		return this.clientService.put(`${this.prefix}/${meetingId}`, payload);
	}

	public async deleteMeeting(meetingId: number): Promise<AxiosResponse<number>> {
		return this.clientService.delete(`${this.prefix}/${meetingId}`);
	}

	public async getAllCollegialMeetings({
		filters,
		...params
	}: GetCollegialsPayload): Promise<AxiosResponse<GetAllCollegialMeetingsResponse>> {
		return this.clientService.post(`${this.prefix}/list`, filters, {
			params,
		});
	}

	public async moveToActive(meetingId: number): Promise<AxiosResponse<void>> {
		return this.clientService.put(`${this.prefix}/${meetingId}/active`);
	}

	public async moveSignToActive(meetingId: number, payload: MoveSignToActivePayload): Promise<AxiosResponse<void>> {
		return this.clientService.put(`${this.prefix}/${meetingId}/sign-to-active`, payload);
	}

	public async moveToSign(meetingId: number): Promise<AxiosResponse<void>> {
		return this.clientService.put(`${this.prefix}/${meetingId}/sign`);
	}

	public async moveToArchive(meetingId: number): Promise<AxiosResponse<void>> {
		return this.clientService.put(`${this.prefix}/${meetingId}/archive`);
	}

	public async moveToDone(meetingId: number): Promise<AxiosResponse<void>> {
		return this.clientService.put(`${this.prefix}/${meetingId}/done`);
	}

	public async generateProtocolFile(meetingId: number): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`${this.prefix}/${meetingId}/protocol/docx`, {
			responseType: 'blob',
		});
	}

	public async uploadProtocolFile(meetingId: number, formData: FormData): Promise<AxiosResponse<string>> {
		return this.clientService.post(`${this.prefix}/${meetingId}/protocol`, formData);
	}

	public async deleteProtocol(meetingId: number): Promise<AxiosResponse<void>> {
		return this.clientService.delete(`${this.prefix}/${meetingId}/protocol`);
	}
}
