import { AxiosResponse } from 'axios';

import { GetActualSelectionsPayload, GetSelectionsPayload } from '@/api/selections/request';
import {
	GetActualSelectionsResponse,
	GetRoleInSelectionResponse,
	GetSelectionResponse,
	GetSelectionsResponse,
} from '@/api/selections/response';
import { Permission } from '@/features/permissions';
import { ClientService } from '@/types/api';

export class SelectionsApi {
	private prefix = 'v2/selection';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getSelections({
		filters,
		...params
	}: GetSelectionsPayload): Promise<AxiosResponse<GetSelectionsResponse>> {
		return this.clientService.post(`${this.prefix}/list`, filters, {
			params,
		});
	}

	public async getSelection(id: number): Promise<AxiosResponse<GetSelectionResponse>> {
		return this.clientService.get(`${this.prefix}/${id}`);
	}

	public async createSelection(payload: FormData): Promise<AxiosResponse<number>> {
		return this.clientService.post(`${this.prefix}`, payload);
	}

	public async updateSelection(id: number, payload: FormData): Promise<AxiosResponse<number>> {
		return this.clientService.put(`${this.prefix}/${id}`, payload);
	}

	public async getRoleInSelection(id: number): Promise<AxiosResponse<GetRoleInSelectionResponse>> {
		return this.clientService.get(`${this.prefix}/${id}/role`);
	}

	public async getActualSelections(
		params?: GetActualSelectionsPayload
	): Promise<AxiosResponse<GetActualSelectionsResponse> | null> {
		if (Permission.instance.canNotAccess('projectsActualSelections')) {
			return null;
		}
		return this.clientService.get(`${this.prefix}/actual`, { params });
	}

	public async deleteSelection(id: number): Promise<boolean> {
		return this.clientService.delete(`${this.prefix}/${id}`);
	}

	public async closeSelection(id: number): Promise<boolean> {
		return this.clientService.delete(`${this.prefix}/${id}/close`);
	}

	public async closeDirection(id: number): Promise<boolean> {
		return this.clientService.delete(`${this.prefix}/${id}/direction/close`);
	}
}
