import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

import {
	AdminDeleteConsentsPayload,
	AdminGetConsentsPayload,
	AdminGetConsentsResponse,
	CreateConsentsPayload,
	GetConsentsPayload,
	GetUserConsentHistoryPayload,
} from '@/api/consents/request';
import { GetConsentsResponse, GetUserConsentHistoryResponse } from '@/api/consents/response';
import PopupNotification from '@/domain/core/PopupNotification';
import { ConsentDocumentType } from '@/features/admin/consents/dtos/consents';
import { ClientService } from '@/types/api';

export class ConsentsApi {
	private prefix = 'v2/consents-document-version';

	private prefixConsents = 'v2/user-consents';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	/**
	 * Получить список документов для таблицы
	 */
	public async getConsentDocumentList({
		filters,
		...params
	}: GetConsentsPayload): Promise<AxiosResponse<GetConsentsResponse>> {
		return this.clientService.post(`${this.prefix}/list`, filters, {
			params,
		});
	}

	/**
	 * Получить список документов согласий
	 */
	public async getConsentsDocumentTypes(): Promise<ConsentDocumentType[]> {
		const result = await this.clientService.get<ConsentDocumentType[]>(`${this.prefix}/document-types`);

		return result.data;
	}

	/**
	 * Загрузить новый документ согласия
	 */
	public async createConsent(payload: CreateConsentsPayload): Promise<CreateConsentsPayload> {
		const result = await this.clientService.post<CreateConsentsPayload>(`${this.prefix}`, payload);

		return result.data;
	}

	/**
	 * Удалить согласения пользователя Админом
	 */
	public async adminDeleteConsents(payload: AdminDeleteConsentsPayload): Promise<AdminDeleteConsentsPayload> {
		const result = await this.clientService.post<AdminDeleteConsentsPayload>(
			`${this.prefixConsents}/user/${payload.userId}`,
			payload
		);

		return result.data;
	}

	/**
	 * Удалить согласения пользователя Админом
	 */
	public async adminGetUserConsents(userId: number): Promise<AdminGetConsentsResponse> {
		const result = await this.clientService.get<AdminGetConsentsResponse>(`${this.prefixConsents}/user/${userId}`);

		return result.data;
	}

	/**
	 * Скачать документ согласия/политики
	 */
	public async getConsentDocument(docId: 1 | 2 | 3, title: string = 'consent') {
		const result = await this.clientService.get<Blob>(`${this.prefix}/actual-document/${docId}`, {
			responseType: 'blob',
		});

		FileSaver.saveAs(result.data, `${title}.docx`);
	}

	/**
	 * Скачать документ акта удаления
	 */
	public async getRemovalActDocument(docId: number | string, title: string = 'акт_об_удалении_персональных_данных') {
		try {
			const result = await this.clientService.get<Blob>(`${this.prefixConsents}/removal-act/${+docId}`, {
				responseType: 'blob',
			});
			FileSaver.saveAs(result.data, `${docId}_${title}.pdf`);
		} catch (e) {
			PopupNotification.emitter('error', 'Ошибка формирования акта удаления');
		}
	}

	public getHistory(
		userId: number,
		params: GetUserConsentHistoryPayload
	): Promise<AxiosResponse<GetUserConsentHistoryResponse>> {
		return this.clientService.post(`${this.prefixConsents}/list/${userId}`, null, {
			params,
		});
	}
}
