import { CreateSetCriteriaPayload, UpdateSetCriteriaPayload } from '@/api/criteria/request';
import { GetCriteriaSetsResponse, GetCriteriaSetWithChildrenResponse } from '@/api/criteria/response';
import { ClientService } from '@/types/api';

export class CriteriaAPI {
	private prefix = 'v2/criteria-set';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	/**
	 * Получить список наборов критериев
	 */
	public async getAllSetCriteria(): Promise<GetCriteriaSetsResponse> {
		const result = await this.clientService.get<GetCriteriaSetsResponse>(`${this.prefix}`);

		return result.data;
	}

	/**
	 * Создать набор критериев
	 */
	public async createSetCriteria(payload: CreateSetCriteriaPayload): Promise<GetCriteriaSetsResponse> {
		const result = await this.clientService.post<GetCriteriaSetsResponse>(`${this.prefix}`, payload);

		return result.data;
	}

	/**
	 * Обновить набор критериев
	 */
	public async updateSetCriteria({ id, ...payload }: UpdateSetCriteriaPayload): Promise<GetCriteriaSetsResponse> {
		const result = await this.clientService.patch<GetCriteriaSetsResponse>(`${this.prefix}/${id}`, payload);

		return result.data;
	}

	/**
	 * Удалить набор критериев
	 */
	public async deleteSetCriteria(id: number): Promise<void> {
		await this.clientService.delete<GetCriteriaSetsResponse>(`${this.prefix}/${id}`);
	}

	/**
	 * Получить набор критериев
	 */
	public async getCriteriaById(id: number): Promise<GetCriteriaSetWithChildrenResponse> {
		const result = await this.clientService.get<GetCriteriaSetWithChildrenResponse>(`${this.prefix}/${id}`);

		return result.data;
	}
}
