import { ApplicationsApi } from '@/api/applications';
import { BusinessProcessesAPI } from '@/api/businessProcesses';
import { CollegialsApi } from '@/api/collegials';
import { ConsentsApi } from '@/api/consents';
import { CriteriaAPI } from '@/api/criteria';
import { DictionariesApi } from '@/api/dictionaries';
import { FilesApi } from '@/api/files';
import { ImportFilesApi } from '@/api/importFiles';
import { InformingAPI } from '@/api/informing';
import { PartnersApi } from '@/api/partners';
import { ProjectApi } from '@/api/project';
import { ReportsApi } from '@/api/reports';
import { RoadmapApi } from '@/api/roadmap';
import { RolesApi } from '@/api/roles';
import { SelectionsApi } from '@/api/selections';
import { UsersApi } from '@/api/users';
import { ClientService } from '@/types/api';
import { backendApi } from '@/utils/api';

export class Api {
	private static _instance: Api;

	public static get instance(): Api {
		this._instance = this._instance || new Api();
		return this._instance;
	}

	private client: ClientService = backendApi;

	public filesApi = new FilesApi(this.client);

	public usersApi = new UsersApi(this.client);

	public applicationsApi = new ApplicationsApi(this.client);

	public businessProcessesApi = new BusinessProcessesAPI(this.client);

	public criteriaApi = new CriteriaAPI(this.client);

	public dictionariesApi = new DictionariesApi(this.client);

	public importFilesApi = new ImportFilesApi(this.client);

	public informingApi = new InformingAPI(this.client);

	public projectApi = new ProjectApi(this.client);

	public roadmapApi = new RoadmapApi(this.client);

	public consentsApi = new ConsentsApi(this.client);

	public reportsApi = new ReportsApi(this.client);

	public selectionsApi = new SelectionsApi(this.client);

	public rolesApi = new RolesApi(this.client);

	public collegialsApi = new CollegialsApi(this.client);

	public partnersApi = new PartnersApi(this.client);
}
