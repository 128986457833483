import { CreateSetInformingPayload, UpdateSetInformingPayload } from '@/api/informing/request';
import { GetInformingSetsResponse, GetInformingSetWithChildrenResponse } from '@/api/informing/response';
import { InformingDomainEvent, RecipientRoleType } from '@/features/admin/informing/dtos/informing';
import { ClientService } from '@/types/api';

export class InformingAPI {
	private prefix = 'notification-template';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	/**
	 * Получить список наборов уведомлений
	 */
	public async getAllSetInforming(): Promise<GetInformingSetsResponse> {
		const result = await this.clientService.get<GetInformingSetsResponse>(`${this.prefix}`);

		return result.data;
	}

	/**
	 * Получить список доменных событий
	 */
	public async getInformingDomainEvents(): Promise<InformingDomainEvent[]> {
		const result = await this.clientService.get<InformingDomainEvent[]>('domain-events');

		return result.data;
	}

	/**
	 * Получить список ролей
	 */
	public async getInformingRecipientRoles(): Promise<RecipientRoleType[]> {
		const result = await this.clientService.get<RecipientRoleType[]>('roles');

		return result.data;
	}

	/**
	 * Создать набор уведомлений
	 */
	public async createSetInforming(payload: CreateSetInformingPayload): Promise<GetInformingSetsResponse> {
		const result = await this.clientService.post<GetInformingSetsResponse>(`${this.prefix}`, payload);

		return result.data;
	}

	/**
	 * Обновить набор уведомлений
	 */
	public async updateSetInforming({ id, ...payload }: UpdateSetInformingPayload): Promise<GetInformingSetsResponse> {
		const result = await this.clientService.patch<GetInformingSetsResponse>(`${this.prefix}/${id}`, payload);

		return result.data;
	}

	/**
	 * Удалить набор уведомлений
	 */
	public async deleteSetInforming(id: number): Promise<void> {
		await this.clientService.delete<GetInformingSetsResponse>(`${this.prefix}/${id}`);
	}

	/**
	 * Получить набор уведомлений
	 */
	public async getInformingById(id: number): Promise<GetInformingSetWithChildrenResponse> {
		const result = await this.clientService.get<GetInformingSetWithChildrenResponse>(`${this.prefix}/${id}`);

		return result.data;
	}
}
