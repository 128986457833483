import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';

import { cn } from '@/utils';

interface ITextProps {
	as?: 'p' | 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'a';
	className?: string;
	children?: React.ReactNode;
}

const textVariants = cva('font-sans', {
	variants: {
		variant: {
			title: 'text-brand-dark-blue text-4xl font-normal leading-tight',
			subtitle: 'text-brand-dark-blue text-[32px] font-normal leading-normal',
			header4: 'text-brand-dark-blue text-2xl leading-tight',
			header5: 'text-brand-dark-blue text-lg leading-tight font-semibold',
			label: 'text-brand-dark-blue text-sm font-semibold leading-tight',
			description: 'text-brand-gray-2 text-sm leading-tight',
			labelDescription: 'text-brand-gray-2 text-[11px] leading-tight',
			paragraph: 'text-brand-dark-blue text-[13px] leading-tight',
			accent: 'text-brand-dark-blue text-lg leading-[27px]',
		},
		overflow: {
			initial: '',
			ellipsis: 'text-ellipsis overflow-hidden',
			truncate: 'truncate',
			clip: 'text-clip overflow-hidden',
		},
		link: {
			true: 'text-brand-dark-blue',
		},
		disabled: {
			true: 'text-brand-gray-3',
		},
	},
	defaultVariants: {
		overflow: 'initial',
		link: false,
	},
});

const Text = React.forwardRef<HTMLSpanElement, ITextProps & VariantProps<typeof textVariants>>((props, ref) => {
	const { as: Component = 'span', variant, link, overflow, disabled, className, children, ...restProps } = props;

	return (
		<Component
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			ref={ref}
			className={cn(textVariants({ variant, overflow, link, disabled, className }))}
			{...restProps}
		>
			{children}
		</Component>
	);
});

export { Text, textVariants };
