import { AxiosResponse } from 'axios';

import {
	CreateDictionaryElementPayload,
	DeleteDictionaryElementPayload,
	GetDictionaryElementsPayload,
	GetDictionaryElementUsagePayload,
	GetDictionaryFilterPayload,
	UpdateDictionaryElementPayload,
} from '@/api/dictionaries/request';
import {
	CreateDictionaryElementResponse,
	DeleteDictionaryElementResponse,
	GetDictionariesElementsResponse,
	GetDictionariesResponse,
	GetDictionariesStatisticResponse,
	GetDictionaryElementUsageResponse,
	GetDictionaryFiltersResponse,
	UpdateDictionaryElementResponse,
} from '@/api/dictionaries/response';
import { ClientService } from '@/types/api';

export class DictionariesApi {
	private prefix = 'v2/dictionaries';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getDictionaries(): Promise<AxiosResponse<GetDictionariesResponse>> {
		return this.clientService.get(`${this.prefix}`);
	}

	public async getElements({
		dictionaryId,
		selectionId,
	}: GetDictionaryElementsPayload): Promise<AxiosResponse<GetDictionariesElementsResponse>> {
		return this.clientService.get(`${this.prefix}/${dictionaryId}/elements`, {
			params: {
				selectionId,
			},
		});
	}

	public async getElementsUsage({
		dictionaryId,
		elementId,
	}: GetDictionaryElementUsagePayload): Promise<AxiosResponse<GetDictionaryElementUsageResponse>> {
		return this.clientService.get(`${this.prefix}/usage/${dictionaryId}/elements/${elementId}`);
	}

	public async createElement({
		dictionaryId,
		...body
	}: CreateDictionaryElementPayload): Promise<AxiosResponse<CreateDictionaryElementResponse>> {
		return this.clientService.post(`${this.prefix}/${dictionaryId}/elements`, body);
	}

	public async updateElement({
		dictionaryId,
		elementId,
		...body
	}: UpdateDictionaryElementPayload): Promise<AxiosResponse<UpdateDictionaryElementResponse>> {
		return this.clientService.patch(`${this.prefix}/${dictionaryId}/elements/${elementId}`, body);
	}

	public async deleteElement({
		dictionaryId,
		elementId,
	}: DeleteDictionaryElementPayload): Promise<AxiosResponse<DeleteDictionaryElementResponse>> {
		return this.clientService.delete(`${this.prefix}/${dictionaryId}/elements/${elementId}`);
	}

	public async getStatistic(): Promise<AxiosResponse<GetDictionariesStatisticResponse>> {
		return this.clientService.get(`${this.prefix}/statistic`);
	}

	public async getFilter(payload: GetDictionaryFilterPayload): Promise<AxiosResponse<GetDictionaryFiltersResponse>> {
		return this.clientService.get(`v2/general-dictionaries/${payload.name}`, {
			params: {
				searchValue: payload.searchValue,
				page: payload.page,
				take: payload.take,
			},
		});
	}
}
