import { GetTrackersResponse } from '@/api/project/response';
import { Permission } from '@/features/permissions';
import { ClientService } from '@/types/api';

export class ProjectApi {
	private prefix = 'projects';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getTrackers() {
		const defaultTrackers: GetTrackersResponse = [];
		return Permission.instance.canNotAccess('projectUpdateTracker')
			? { data: defaultTrackers }
			: this.clientService.get(`${this.prefix}/trackers`);
	}
}
