import { AxiosResponse } from 'axios';

import { GetApplicationsExport, GetApplicationsExportRating, GetApplicationsPayload } from '@/api/applications/request';
import {
	GetApplicationColumnsResponse,
	GetApplicationResponse,
	GetApplicationsResponse,
} from '@/api/applications/response';
import { ClientService } from '@/types/api';

export class ApplicationsApi {
	private prefix = 'v2/applications';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getApplications({
		filters,
		...params
	}: GetApplicationsPayload): Promise<AxiosResponse<GetApplicationsResponse>> {
		return this.clientService.post(`${this.prefix}`, filters, {
			params,
		});
	}

	public async getApplication(id: number): Promise<AxiosResponse<GetApplicationResponse>> {
		return this.clientService.get(`${this.prefix}/${id}`);
	}

	public async getColumns(): Promise<AxiosResponse<GetApplicationColumnsResponse>> {
		return this.clientService.get(`${this.prefix}/columns`);
	}

	public async getExport({ filters, ...params }: GetApplicationsExport): Promise<AxiosResponse<Blob>> {
		return this.clientService.post(`${this.prefix}/export`, filters, {
			params,
			responseType: 'blob',
		});
	}

	public async getRatingExport({ filters, ...params }: GetApplicationsExportRating): Promise<AxiosResponse<Blob>> {
		return this.clientService.post(`${this.prefix}/export-rating`, filters, {
			params,
			responseType: 'blob',
		});
	}

	public async getBRICSExport(): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`v2/project-passport-export/all/579`, {
			responseType: 'blob',
		});
	}

	public async getCapApplications(projectIds: number[]): Promise<AxiosResponse<Blob>> {
		const search = new URLSearchParams(projectIds.map((id) => ['projectIds', id.toString()]));

		return this.clientService.get(`${this.prefix}/export-first-level-criteria?${search.toString()}`, {
			responseType: 'blob',
		});
	}
}
