import { AxiosResponse } from 'axios';

import { PartnerListDto } from '@/api/generated/models';
import { GetColumnFiltersPayload, GetPartnersExportPayload, GetPartnersPayload } from '@/api/partners/request';
import { GetApplicationColumnFilterResponse, GetPartnersColumnsResponse } from '@/api/partners/response';
import { ClientService } from '@/types/api';

export class PartnersApi {
	private prefix = 'v2/partner';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getPartners({ filters, ...params }: GetPartnersPayload): Promise<AxiosResponse<PartnerListDto>> {
		return this.clientService.post(`${this.prefix}/list`, filters, {
			params,
		});
	}

	public async getColumns(): Promise<AxiosResponse<GetPartnersColumnsResponse>> {
		return this.clientService.get(`${this.prefix}/columns`);
	}

	public async getColumnFilters({
		key,
		...payload
	}: GetColumnFiltersPayload): Promise<AxiosResponse<GetApplicationColumnFilterResponse>> {
		return this.clientService.get(`${this.prefix}/columns/filters/${key}`, {
			params: payload,
		});
	}

	public async getExport({ filters, ...params }: GetPartnersExportPayload): Promise<AxiosResponse<Blob>> {
		return this.clientService.post(`${this.prefix}/excel`, filters, {
			params,
			responseType: 'blob',
		});
	}
}
