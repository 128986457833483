import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

import { ClientService } from '@/types/api';

export class FilesApi {
	private prefix = 'v2/files';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getFile(fileId: string | number): Promise<AxiosResponse<Blob>> {
		return this.clientService.get(`${this.prefix}/${fileId}`, {
			responseType: 'blob',
		});
	}

	public async saveFile(fileId: string | number, filename: string): Promise<void> {
		const { data } = await this.getFile(fileId);

		FileSaver.saveAs(data, filename);
	}
}
