import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

import { ImportFilesPayload } from '@/api/importFiles/request';
import { ImportFilesResponse } from '@/api/importFiles/response';
import { ClientService } from '@/types/api';

export class ImportFilesApi {
	private prefix = '/v2/excel';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	// eslint-disable-next-line class-methods-use-this
	_saveFile(fileData: string | Blob, fileName: string) {
		FileSaver.saveAs(fileData, fileName);
	}

	public async getMajorTemplate(selectionId: number, fileName?: string, format = 'xlsx') {
		const filePrefix = 'Шаблон проектов';
		const result = await this.clientService.get<Blob>(`${this.prefix}/export/${selectionId}/template`, {
			responseType: 'blob',
		});
		this._saveFile(result.data, `${filePrefix}${fileName ? ` - ${fileName}` : ''}.${format}`);
	}

	public async getPdnTemplate(selectionId: number, fileName?: string, format = 'xlsx') {
		// `/v2/excel/export/${selectionId}/template-pdn`;
		const filePrefix = 'Шаблон ПДн';
		const result = await this.clientService.get<Blob>(`${this.prefix}/export/${selectionId}/template-pdn`, {
			responseType: 'blob',
		});
		this._saveFile(result.data, `${filePrefix}${fileName ? ` - ${fileName}` : ''}.${format}`);
	}

	public async importFiles(
		selectionId: number,
		payload: ImportFilesPayload
	): Promise<AxiosResponse<ImportFilesResponse>> {
		return this.clientService.post(`${this.prefix}/import/${selectionId}`, {
			...payload,
		});
	}

	public async getImportReport(reportId: number, fileName = 'Отчет ошибок', format = 'xlsx') {
		const result = await this.clientService.get<Blob>(`${this.prefix}/import/report/${reportId}`, {
			responseType: 'blob',
		});
		this._saveFile(result.data, `${fileName}.${format}`);
	}
}
