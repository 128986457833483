import { AxiosResponse } from 'axios';

import { GetUsersPayload } from '@/api/users/request';
import { GetUsersResponse } from '@/api/users/response';
import { ClientService } from '@/types/api';

export class UsersApi {
	private prefix = 'v2/users';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getUsers(payload: GetUsersPayload): Promise<AxiosResponse<GetUsersResponse>> {
		return this.clientService.get(`${this.prefix}`, {
			params: payload,
		});
	}
}
