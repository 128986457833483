import { AxiosResponse, type AxiosRequestConfig } from 'axios';

import {
	CompleteRoadmapTaskPayload,
	CreateRoadmapSubtaskPayload,
	CreateRoadmapTaskPayload,
	CreateRoadmapTemplateFromUsedPayload,
	CreateRoadmapTemplatePayload,
	DeleteRoadmapTaskPayload,
	GetRoadmapDetailsPayload,
	UpdateRoadmapPayload,
	UpdateRoadmapTaskPayload,
	UpdateRoadmapTemplatePayload,
	UploadFileRoadmapTaskPayload,
	UploadRoadmapDocument
} from '@/api/roadmap/request';
import {
	GetRoadmapResponse,
	GetRoadmapTaskDetailsResponse,
	GetRoadmapTemplateDetailsResponse,
	GetRoadmapTemplatesResponse,
	GetTaskStatusesResponse,
	GetTaskTypesResponse,
	GetTemplateStatusesResponse,
} from '@/api/roadmap/response';
import { ClientService } from '@/types/api';
import { MyActiveRoadmapTemplateDTO } from '@/features/shared/dtos/roadmap';

export class RoadmapApi {
	private prefix = 'v2/roadmap';

	private templatePrefix = 'v2/roadmap-templates';

	private dictionaryPrefix = 'v2/roadmap-dictionary';

	private templateStatuses = 'v2/dictionary-statuses';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getRoadmapTemplates(): Promise<AxiosResponse<GetRoadmapTemplatesResponse>> {
		return this.clientService.get(`${this.templatePrefix}`);
	}

	public async getRoadmapTemplateDetails(
		templateId: number
	): Promise<AxiosResponse<GetRoadmapTemplateDetailsResponse>> {
		return this.clientService.get(`${this.templatePrefix}/${templateId}`);
	}

	public async createRoadmapTemplate(payload: CreateRoadmapTemplatePayload): Promise<AxiosResponse<number>> {
		return this.clientService.post(`${this.templatePrefix}`, payload);
	}

	public async createRoadmapTemplateFromUsed({
		roadmapId,
		...payload
	}: CreateRoadmapTemplateFromUsedPayload): Promise<AxiosResponse<number>> {
		return this.clientService.post(`${this.templatePrefix}/roadmap/${roadmapId.toString()}`, payload);
	}

	public async updateRoadmapTemplate({
		templateId,
		...body
	}: UpdateRoadmapTemplatePayload): Promise<AxiosResponse<number>> {
		return this.clientService.put(`${this.templatePrefix}/${templateId}`, body);
	}

	public async deleteRoadmapTemplate(id: number): Promise<void> {
		await this.clientService.delete(`${this.templatePrefix}/${id}`);
	}

	public async getRoadmap<Params extends AxiosRequestConfig['params']>(
		roadmapId: number,
		params?: Params
	): Promise<AxiosResponse<GetRoadmapResponse>> {
		return this.clientService.get(`${this.prefix}/${roadmapId}`, { params });
	}

	public async updateRoadmap({ roadmapId, ...body }: UpdateRoadmapPayload): Promise<AxiosResponse<number>> {
		return this.clientService.patch(`${this.prefix}/${roadmapId}`, body);
	}

	public async createRoadmap(applicationId: number, templateId?: number): Promise<AxiosResponse<number>> {
		if (templateId) {
			return this.clientService.post(`${this.prefix}/${applicationId}?templateId=${templateId}`);
		}

		return this.clientService.post(`${this.prefix}/${applicationId}`);
	}

	public async getAllMyActiveRoadmapTemplates(): Promise<AxiosResponse<MyActiveRoadmapTemplateDTO[]>> {
		return this.clientService.get(`${this.templatePrefix}/my`);
	}

	public async getRoadmapTaskDetails({
		roadmapId,
		taskId,
		...params
	}: GetRoadmapDetailsPayload): Promise<AxiosResponse<GetRoadmapTaskDetailsResponse>> {
		return this.clientService.get(`${this.prefix}/${roadmapId}/task/${taskId}`, {
			params,
		});
	}

	public async createRoadmapTask({ roadmapId, ...body }: CreateRoadmapTaskPayload): Promise<AxiosResponse<number>> {
		return this.clientService.post(`${this.prefix}/${roadmapId}/task`, body);
	}

	public async createRoadmapSubtask({
		roadmapId,
		parentTaskId,
		...body
	}: CreateRoadmapSubtaskPayload): Promise<AxiosResponse<number>> {
		return this.clientService.post(`${this.prefix}/${roadmapId}/task/${parentTaskId}`, body);
	}

	public async updateRoadmapTask({
		roadmapId,
		taskId,
		...body
	}: UpdateRoadmapTaskPayload): Promise<AxiosResponse<number>> {
		return this.clientService.patch(`${this.prefix}/${roadmapId}/task/${taskId}`, body);
	}

	public async completeRoadmapTask({ roadmapId, taskId }: CompleteRoadmapTaskPayload): Promise<void> {
		return this.clientService.put(`${this.prefix}/${roadmapId}/task/${taskId}/status/done`);
	}

	public async uploadFileRoadmapTask({ roadmapId, taskId, file }: UploadFileRoadmapTaskPayload): Promise<void> {
		return this.clientService.put(`${this.prefix}/${roadmapId}/task/${taskId}/fact-result-file`, file);
	}

	public async deleteFileRoadmapTask({ roadmapId, taskId }: CompleteRoadmapTaskPayload): Promise<void> {
		return this.clientService.delete(`${this.prefix}/${roadmapId}/task/${taskId}/fact-result-file`);
	}

	public async deleteRoadmapTask({ roadmapId, taskId }: DeleteRoadmapTaskPayload): Promise<void> {
		await this.clientService.delete(`${this.prefix}/${roadmapId}/task/${taskId}`);
	}

	public async getTaskTypes(): Promise<AxiosResponse<GetTaskTypesResponse>> {
		return this.clientService.get(`${this.dictionaryPrefix}/tasks`);
	}

	public async getTaskStatuses(): Promise<AxiosResponse<GetTaskStatusesResponse>> {
		return this.clientService.get(`${this.dictionaryPrefix}/statuses`);
	}

	public async getTemplateStatuses(): Promise<AxiosResponse<GetTemplateStatusesResponse>> {
		return this.clientService.get(`${this.templateStatuses}`);
	}

	// NOTE: Upload document service
	public async uploadRoadmapDocument({ roadmapId, type, data }: UploadRoadmapDocument): Promise<{ data: any }> {
		return this.clientService.post(`${this.prefix}-documents/${roadmapId}/type/${type}`, data);
	}

	public async updateRoadmapDocument({ documentId, data }: { documentId: string, data: { description: string } }): Promise<void> {
		return this.clientService.put(`${this.prefix}-documents/${documentId}`, data);
	}

	public async deleteRoadmapDocument({ documentId }: { documentId: string }): Promise<{ data: any }> {
		return this.clientService.delete(`${this.prefix}-documents/${documentId}`);
	}

	public async replaceRoadmapDocument({ documentId, data }: { documentId: string, data: any }): Promise<{ data }> {
		return this.clientService.put(`${this.prefix}-documents/${documentId}/replace`, data);
	}

	public async getRoadmapDocumentList({ roadmapId }: { roadmapId: number }): Promise<{ data }> {
		return this.clientService.get(`${this.prefix}-documents/${roadmapId}`);
	}
}
