import { AxiosResponse } from 'axios';

import { CreateRolePayload, GetRolesPayload, UpdateRolePayload } from '@/api/roles/request';
import { GetRolesColumnsResponse, GetRolesResponse, RoleNameUniqCheckResponse } from '@/api/roles/response';
import { RoleDTO } from '@/features/admin/roles/dtos/roles';
import { ClientService } from '@/types/api';

export class RolesApi {
	private prefix = 'role';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	public async getRoles({ filters, ...params }: GetRolesPayload): Promise<AxiosResponse<GetRolesResponse>> {
		return this.clientService.post(`${this.prefix}/list`, filters, { params });
	}

	public async getColumns(): Promise<AxiosResponse<GetRolesColumnsResponse>> {
		return this.clientService.get(`${this.prefix}/columns`);
	}

	public async getRole(id: number): Promise<AxiosResponse<RoleDTO>> {
		return this.clientService.get(`${this.prefix}/${id}`);
	}

	public async createRole(payload: CreateRolePayload): Promise<AxiosResponse<void>> {
		return this.clientService.post(this.prefix, payload);
	}

	public async updateRole(id: number, payload: UpdateRolePayload): Promise<AxiosResponse<void>> {
		return this.clientService.patch(`${this.prefix}/${id}`, payload);
	}

	public async deleteRole(id: number): Promise<AxiosResponse<void>> {
		return this.clientService.delete(`${this.prefix}/${id}`);
	}

	public async roleNameUniqCheck(roleName: string): Promise<AxiosResponse<RoleNameUniqCheckResponse>> {
		return this.clientService.get(`${this.prefix}/${roleName}/unique_check`);
	}
}
