import {
	CreateBusinessProcessPayload,
	GetBusinessProcessListPayload,
	UpdateBusinessProcessPayload,
} from '@/api/businessProcesses/request';
import {
	GetBusinessProcessActionsResponse,
	GetBusinessProcessListResponse,
	GetBusinessProcessResponse,
	OldBusinessProcessResponse,
} from '@/api/businessProcesses/response';
import { BusinessProcessDictionaryDto } from '@/api/generated/models';
import { OldBusinessProcessDTO } from '@/features/businessProcesses/dtos/businessProcess';
import { ClientService } from '@/types/api';

export class BusinessProcessesAPI {
	private prefix = 'v2/business-processes';

	constructor(private readonly clientService: ClientService) {
		this.clientService = clientService;
	}

	/**
	 *
	 * @param projectId
	 */
	public async getBusinessProcessesByProjectId(projectId: number): Promise<GetBusinessProcessResponse> {
		const { data } = await this.clientService.get<GetBusinessProcessResponse>(
			`${this.prefix}/project/${projectId}`
		);

		return data;
	}

	public async getOldBusinessProcessesByProjectId(projectId: number): Promise<OldBusinessProcessDTO> {
		const { data } = await this.clientService.get<OldBusinessProcessResponse>(
			`${this.prefix}/old/project/${projectId}`
		);

		const oldProcessPreset: OldBusinessProcessDTO = {
			maxExpertsAmount: data ? data.maxExpertsAmount : 2,
			minExpertsAmount: data ? data.minExpertsAmount : 1,
			id: data?.id,
			isExpertizeEnabled: data ? data.isExpertizeEnabled : true,
			isFirstLevelEnabled: data ? data.isFirstLevelEnabled : true,
			isQuickEditAllowedWithoutProtocol: data ? data.isQuickEditAllowedWithoutProtocol : false,
		};

		return oldProcessPreset;
	}

	public async createBusinessProcess(payload: CreateBusinessProcessPayload) {
		return this.clientService.post<number>(`${this.prefix}`, payload);
	}

	public async updateBusinessProcess({ businessProcessId, ...body }: UpdateBusinessProcessPayload) {
		return this.clientService.put<number>(`${this.prefix}/${businessProcessId}`, body);
	}

	public async deleteBusinessProcess(id: number) {
		await this.clientService.delete(`${this.prefix}/${id}`);
	}

	public async getBusinessProcess(businessProcessId: number) {
		return this.clientService.get<GetBusinessProcessResponse>(`${this.prefix}/${businessProcessId}`);
	}

	public async getBusinessProcessList({ filters, ...params }: GetBusinessProcessListPayload) {
		return this.clientService.post<GetBusinessProcessListResponse>(`${this.prefix}/list`, filters, {
			params,
		});
	}

	public async getBusinessProcessSelectList() {
		return this.clientService.post<BusinessProcessDictionaryDto[]>(`${this.prefix}/select-list`);
	}

	public async getBusinessProcessActions() {
		const { data } = await this.clientService.get<GetBusinessProcessActionsResponse>(`${this.prefix}/actions`);

		return data;
	}
}
